import { useConfig, usePageVariation } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent'
import styles from './TrackingWrapper.module.scss'
import { useEventScope } from 'hooks/useEventScope'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'
import { useRouter } from 'next/router'
import { SectionContext } from 'providers/SectionProvider'
import { useContext } from 'react'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import {
  OFF_EXPERIMENT,
  PHONE_NUMBERS_NAME_EXPERIMENT,
  UNASSIGNED_EXPERIMENT,
} from 'constants/experiments'
import { isGetStartedRoute } from 'utils/validations/isRoutes'

function TrackingWrapper({
  track,
  onClick,
  withNextLink,
  children,
  trackAsConversion = false,
  ...props
}) {
  const { config } = useConfig()
  const { pageVariation } = usePageVariation()
  const { analyticsTrackEvent } = useAnalyticsTrackEvent()
  const specificity = useEventScope()
  const router = useRouter()
  let url = router.asPath
  const variationContext = useContext(SectionContext)
  const enablePhoneNumbers = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS)
  const localClickHandler = (event, track, onClick, withNextLink, trackAsConversion) => {
    const getTrackingObject = (track) => {
      track = updateTrackWithListedEvent(track, specificity)
      // use experiments array if it exists, otherwise create it empty one
      track.experiments = Array.isArray(track.experiments) ? track.experiments : []
      //only add experiments to the track if the href is get-started
      if (
        isGetStartedRoute(track?.href) ||
        isGetStartedRoute(track?.click_url) ||
        trackAsConversion
      ) {
        // When the phone number experiment is on we add the experiment to track
        if (
          enablePhoneNumbers &&
          ![OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT].includes(enablePhoneNumbers)
        ) {
          track.experiments.push({
            experiment_name: PHONE_NUMBERS_NAME_EXPERIMENT,
            experiment_id: FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS,
            variation: enablePhoneNumbers,
            event_type: 'conversion',
          })
        }
        // Page section level experiment
        if (variationContext?.variation?.variant) {
          track.experiments.push({
            experiment_name: variationContext?.variation?.name,
            experiment_id: variationContext?.variation?.featureFlagId,
            variation: variationContext?.variation?.variant,
            event_type: 'conversion',
          })
        }
        // Page level variation experiment
        if (
          pageVariation &&
          pageVariation?.id &&
          pageVariation?.id !== variationContext?.variation?.featureFlagId
        ) {
          track.experiments.push({
            experiment_name: pageVariation?.name,
            experiment_id: pageVariation?.id,
            variation: pageVariation?.variation,
            event_type: 'conversion',
          })
        }
      }

      if (track?.click_type) {
        const trackData = {
          ...track,
          click_text: track.click_text
            ? track.click_text
            : `${config.NEXT_PUBLIC_ANALYTICS_APP_NAME} | ${track.click_id}`,
        }

        if (track?.href) {
          if (track?.href.substring(0, 1) == '#') {
            trackData['click_url'] = url
          } else {
            trackData['click_url'] = `${track.href}`
          }
        }
        return trackData
      } else {
        return track
      }
    }
    if (withNextLink) {
      event.stopPropagation()
      event.preventDefault()
    }

    analyticsTrackEvent(handleTrackAndReactEvent(event, getTrackingObject(track), onClick))
  }

  return (
    <span
      className={styles['wrapper']}
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, onClick, withNextLink, trackAsConversion)
      }}
    >
      <> {children}</>
    </span>
  )
}

export { TrackingWrapper }
