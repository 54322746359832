import { GET_STARTED_ROUTE, DIRECT_MAILER_ROUTE } from 'constants/navigation'

const isGetStartedRoute = (href) => {
  return (
    href !== undefined &&
    href?.trim()?.replace('/', '').startsWith(GET_STARTED_ROUTE.split('?')[0].replace('/', ''))
  )
}

const isDirectMailerRoute = (href) => {
  return (
    href !== undefined &&
    href?.trim()?.replace('/', '').startsWith(DIRECT_MAILER_ROUTE.split('?')[0].replace('/', ''))
  )
}

export { isGetStartedRoute, isDirectMailerRoute }
