export const DEFAULT_EXPERIMENT = 'Control'
export const OFF_EXPERIMENT = 'Off'
export const UNASSIGNED_EXPERIMENT = 'Unassigned'

export const PRODUCT_SPECIFIC_NAME_EXPERIMENT = 'Header product specific CTAs'
export const PHONE_NUMBERS_NAME_EXPERIMENT = 'ACX Phone numbers'

export const BOT_PHONE_NUMBER = '1-800-920-0045'

export const PZN_VALUES = 'acx-pzn-values'

export const disableClientSideRoutes = ['/']

export const getPageVariationByParam = (param) => {
  switch (param) {
    case 'a':
      return 'Variation_A'
    case 'b':
      return 'Variation_B'
    case 'c':
      return 'Variation_C'
    case 'off':
      return 'Off'
    default:
      return 'Control'
  }
}
