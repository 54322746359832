import { useEffect, useState, useContext } from 'react'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { Image } from 'components/Image'
import { Button, Typography } from '@achieve/ascend'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import styles from './ConsentBanner.module.scss'

const ConsentBanner = function ({ isActive, setIsActive, top }) {
  const {
    state: { isPageLoaded },
  } = useContext(AnalyticsContext)

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const [policyLink, setPolicyLink] = useState('/policies')
  const { fireAndForget } = useTriggerEvents()

  // Main event handler to call other sub-handlers
  const handleEvent = (event = {}) => {
    // Handle options sent from client. Messages from the client script will be delivered as a
    // JSON string
    function isJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }

    if (!isJsonString(event.data)) return

    const { options = {} } = JSON.parse(event.data)
    if (Object.keys(options).length) {
      handleOptionsEvent(options)
    }
  }

  const handleOptionsEvent = (options = {}) => {
    const { policyLink: newPolicyLink } = options
    // Update policyLink if a new one was passed in options
    if (policyLink !== newPolicyLink) {
      setPolicyLink(newPolicyLink)
    }
  }

  const onAcceptClick = () => {
    setIsActive(false)
    handleEvent('accept')
  }

  const onCloseClick = () => {
    setIsActive(false)
    handleEvent('close')
  }

  useEffect(() => {
    fireAndForget({
      event_type: 'app_event',
      event_action: 'app_event',
      nav_link_section: 'TCB banner',
      track_event: '_views/policy',
      ...handleTrackAndReactEvent(event, {}),
    })
  }, [isPageLoaded])

  return (
    <>
      {isActive && (
        <div data-position-top={top} className={styles['tcp-banner']}>
          <div className={styles['tcp-content']}>
            <Typography variant={isMobile ? 'bodyXs' : 'bodyM'} className={styles['tcp-msg']}>
              We use and allow cookies and similar tools on our websites to enable essential
              functionalities and to better understand how visitors use our site (including use of
              session replay technology), as well as for personalization, social media features, and
              advertising. Read our
              <TrackingWrapper
                track={{
                  list_name: 'Policies link click event',
                }}
              >
                <a href={policyLink} target="blank">
                  privacy policy
                </a>
              </TrackingWrapper>{' '}
              to learn more. By closing this box or by your continued use of our sites, you accept
              such use.
            </Typography>
            <div className={styles['tcp-btn']}>
              <TrackingWrapper
                track={{
                  list_name: 'Tracking consent click event',
                }}
                onClick={onAcceptClick}
              >
                <Button size="small" variant="contained">
                  <div className={styles['button-padding']}>Ok</div>
                </Button>
              </TrackingWrapper>
            </div>
            <div className={styles['tcp-x']}>
              <TrackingWrapper
                track={{
                  list_name: 'Close consent click event',
                }}
                onClick={onCloseClick}
              >
                <Image
                  src="/close.svg"
                  alt="Close button"
                  width={18}
                  height={18}
                  priority={false}
                  aria-hidden={true}
                />
              </TrackingWrapper>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { ConsentBanner }
